import request from '@/common/axios';
import store from '@/store';

// 电池列表管理
export function fetchBatteryList(data) {
  return request({
    url: '/web/admin/battery/batteryPage',
    method: 'POST',
    data: {
        ...data
    }
  });
}

// 添加
export function addBattery(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 700,
      oReqData: {
        ...data
      }
    }
  });
}

// 添加电池备注
export function addBatteryRemark(data){
  return request({
    url: '/web/admin/battery/batter/remark',
    method: 'POST',
    data: data
  })
}

// 批量导入
export function addBatBattery(data) {
  return request({
    url: '/web/admin/battery/ExceladdDev',
    method: 'POST',
    data: data
  });
}

// 模板导出
export function exportTemplate(data) {
  return request({
    url: '/exceldown',
    method: 'get',
    responseType: 'arraybuffer',
  });
}

// 修改站点
export function updateBattery(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 703,
      oReqData: {
        ...data
      }
    }
  });
}

// 查询电池的 GPS 信息
export function fetchGPSInfo(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 3705,
      oReqData: {
        "page": 1,
        "size": 20,
        "iSortType": 2,
        "sortField": "SOC",
        "query": {
          "WorkStatus": -1,
          "SOC": "",
          "macList": data
        },
      }
    }
  });
}

// 导出 电池设备
export function exportDevice(data) {
  return request({
    url: '/web/admin/battery/batteryExcelDownload',
    method: 'POST',
    responseType: 'arraybuffer',
    data: data
  });
}

export function lockBattery(data) {

}


export function getBatteryState(data) {
  if(data.length===0){
    return new Promise((solve,reject)=>{
      solve({data:{data:{macidStatusList:[]}}})
    });
  }
  return request({
    url: '/api/station/deviceStatusList',
    method: 'post',
    loading: false,
    data: {
      "macidList": data
    }
  })
}

export function getBadBattery(data) {
  return request({
    url: '/web/admin/battery/bad/battery',
    method: 'post',
    data: data
  })
}