<!-- 换电记录 -->
<template>
  <div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column label="用户" prop="userRealName">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="goDetail(scope.row.userId)"
          >
            {{ scope.row.userRealName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="电话" prop="phone"></el-table-column>
      <el-table-column label="电池编号" prop="batteryMacId"></el-table-column>
      <el-table-column label="换电站点" prop="stationMacId"></el-table-column>

      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.USER_DEVICE_STATUS"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>
      <el-table-column label="换电时间" prop="createTime"></el-table-column>
      <el-table-column label="归还站点" prop="backStationMacId"></el-table-column>
      <el-table-column label="归还仓门" prop="backDoorId"></el-table-column>

       <el-table-column label="归还时间" prop="updateTime">
       </el-table-column>
      <el-table-column label="消耗电量" prop="createAt">
        <template #default="scope">
          <span
            >{{ scope.row.soc - scope.row.backSoc }}%</span
          >
        </template>
      </el-table-column>

      <el-table-column label="归还剩余电量" prop="createAt">
        <template #default="scope">
          <span v-if="scope.row.backStationMacId">{{ scope.row.backSoc }}%</span>
        </template>
      </el-table-column>

      <el-table-column label="行驶里程" prop="createAt">
        <template #default="scope">
          <span v-if="scope.row.backMil"
            >{{
              (
                parseFloat(scope.row.backMil) -
                  parseFloat(scope.row.mil)
              ).toFixed(2)
            }}km</span
          >
        </template>
      </el-table-column>

      <el-table-column label="信息" prop="remark"></el-table-column>
      <el-table-column label="还电信息" prop="backRemark"></el-table-column>
     

      <!-- <el-table-column label="操作">
        <template #default="scope">
          <span
            v-if="this.const.USER_DEVICE_STATUS_CODE.USE === scope.row.status"
            class="option option-primary"
            @click="sendMsg(scope.row)"
            >发送逾期信息</span
          >
        </template>
      </el-table-column> -->
    </el-table>

    <el-pagination
      v-if="pageable"
      layout="total, prev, pager, next"
      :page-sizes="[10, 50, 100, 200]"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>

  <BatteryInfoModal
    ref="batteryInfoModal"
    :modalData="modalData"
  ></BatteryInfoModal>
</template>

<script>
import {
  toRefs,
  toRef,
  reactive,
  onMounted,
  isProxy,
  isReactive,
  computed,
  ref,
} from "vue";
import { fetchExchangeRecord, sendTimeoutMsg } from "@/api/batteryMan";
import { validatenull } from "@/common/validate";
import { useStore } from "vuex";
import BatteryInfoModal from "@/components/view-components/battery-info-modal";
import { deepClone } from "@/common/util";
import { ElMessageBox, ElMessage } from "element-plus";
import { useRouter } from "vue-router";

export default {
  props: ["listQuery", "pageable"],
  components: { BatteryInfoModal },

  setup(props, context) {
    console.log(context, "context", props);
    let listQuery = toRef(props, "listQuery");
    let router = useRouter();
    var batteryInfoModal = ref(null);
    const store = useStore();
    const stationList = computed(
      () => store.state.station.stationList || { list: [], map: {} }
    );
    store.dispatch("fetchStationAll");
    const state = reactive({
      stationList: stationList,
      total: 0,
      dataSource: [],
      modalData: {},
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      listQuery: listQuery,
    });

    const goDetail = (id) => {
      router.push("/user/detail/" + id);
    };

    const sendMsg = (row) => {
      ElMessageBox.confirm("是否要发送逾期短信", "提示", {
        type: "warning",
      }).then((res) => {
        sendTimeoutMsg({
          userId: row.user.id,
          type: 1,
          msg: `${row.macid}`,
        }).then((res) => {
          ElMessage({
            message: "操作成功！",
            type: "success",
          });
        });
      });
    };

    const getList = (current) => {
      if (!validatenull(current)) {
        state.pages.pageIndex = current;
      }

      fetchExchangeRecord({ ...state.listQuery, ...state.pages }).then(
        (res) => {
          state.dataSource = res.data.data.records;
          state.total = res.data.data.total;
        }
      );
    };

    const showInfoModal = (row) => {
      state.modalData = deepClone(row);
      batteryInfoModal.value.showModal();
    };

    return {
      ...toRefs(state),
      listQuery: listQuery.value,
      getList,
      sendMsg,
      batteryInfoModal,
      showInfoModal,
      stationList: stationList,
      goDetail,
    };
  },
};
</script>