<template>
  <el-dialog
    title="电池信息"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="电池电量："> 100% </el-form-item>

      <el-form-item label="电池电量："> 100% </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>
      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { fetchGPSInfo } from "@/api/battery";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData"],
  data() {
    return {
      formName: "form",
      info: {},
    };
  },

  methods: {
    showModal() {
      this.isShow = true;
      this.info = {};
      this.$nextTick(() => {
        fetchGPSInfo({
          macid: this.modalData.macid,
        }).then(res=>{
            console.log(res.data.data);
            this.info = res.data;
        })
      });
    },
    submit() {
      this.validateForm().then((res) => {
        let obj = this.deepClone(this.modalData);
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
