<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="10">
        <eb-date-range v-model="dateRange" @change="changeDate"></eb-date-range>
      </el-col>

      <el-col :span="4">
        <el-select
          v-model="listQuery.status"
          placeholder="状态"
          clearable
          @change="getList(1)"
        >
         
          <el-option
            v-for="(item, index) in this.const.USER_DEVICE_STATUS"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-input type="text" v-model="listQuery.remark" placeholder="备注"></el-input>
      </el-col>

    

      <el-col span="4">
        <eb-filter-user-list v-model="listQuery.userId" @change="getList(1)">
        </eb-filter-user-list>
      </el-col>

      <el-col :span="4">
        <el-input v-model="listQuery.batteryMacId" placeholder="电池编号"></el-input>
      </el-col>
 
      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
        <el-button type="primary" @click="doExportExchangeList" v-auth="this.per.EXPORT_EXCHANGE_RECORD">导出换电记录</el-button>
      </el-col>
    </el-row>

    <ExchangeRecord
      ref="ExchangeRecord"
      :listQuery="listQuery"
      :pageable="true"
    ></ExchangeRecord>
  </div>
</template>

<script>
import ExchangeRecord from "@/components/view-components/exchange-record";
import { mapState } from "vuex";
import {exportExchangeList} from '@/api/order';
import moment from 'moment';

export default {
  components: { ExchangeRecord },
  computed: mapState({
    stationList: (state) => {
      return state.station.stationList || { list: [], map: {} };
    },
  }),
  data() {
    let startTime = moment().subtract(7, "d").startOf("d").format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("d").format("YYYY-MM-DD HH:mm:ss");
    
    return {
      dateRange: [startTime, endTime],
      listQuery: {
        
      },
    };
  },
  mounted() {
    this.getList(1);
    this.$store.dispatch("fetchStationAll");
  },

  methods: {
     changeDate(e) {
      if (!this.validatenull(e)) {
        this.listQuery.startTime = moment(e[0])
          .format("YYYY-MM-DD HH:mm:ss");
        this.listQuery.endTime = moment(e[1])
          .format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.listQuery.startTime = "";
        this.listQuery.endTime = "";
      }
    },

    doExportExchangeList(){
      let queryData = this.util.deepClone(this.listQuery);
      queryData.startTime = queryData.startTime;
      queryData.endTime = queryData.endTime;

      exportExchangeList(queryData).then(res=>{
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `${queryData.startTime}-${queryData.endTime}换电记录.xlsx`
        );
      })
    },
    
    getList(current) {
      this.$refs["ExchangeRecord"].getList(current);
    },
  },
};
</script>